
import { Component, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import TheReport from "@/components/TheReport.vue";
import _ from "underscore";
import Notify from "@/utils/notifications";
import { findShipment } from "@/api/tharbad";
import * as CountryService from "@/api/helpers/Country";

interface SortParams {
  columns: Record<string, unknown>;
  order: string | null;
  prop: string;
}

interface Params {
  sort: string | undefined;
  offset: number;
  limit: number;
  campaign?: string;
  "country-code"?: string;
  sender?: string;
  "start-shipment-at"?: string;
  "end-shipment-at"?: string;
}
interface JoinedTable {
  id: number;
  name: string;
}
interface Payload<T> {
  count: number;
  rows: T[];
  "total-credit-spend": number;
  "total-receivers": number;
  "total-mexican-pesos-spend": number;
}

interface Shipment {
  id: number;
  campaign: JoinedTable;
  "country-code": string;
  sender: string;
  receivers: number;
  "credit-spend": number;
  "mexican-pesos-spend": number;
  "shipment-at": string;
  status: JoinedTable;
  "created-at": Date;
  "updated-at": Date;
}
@Component({
  components: {
    DeletePopover,
    TheReport,
  },
})
export default class LeadView extends Vue {
  fullscreenLoading = true;
  defaultParams = {
    sort: "created-at:desc",
    offset: 0,
    limit: 10,
    campaign: undefined,
    "country-code": undefined,
    sender: undefined,
    "start-shipment-at": new Date().toISOString(),
    "end-shipment-at": new Date().toISOString(),
  };
  params: Params = _.clone(this.defaultParams);
  shipmentAt = [new Date(), new Date()];
  campaigns = [{}];
  countries = [{}];
  limitArray = [10, 25, 50, 100, 250, 500, 1000];
  history: Payload<Shipment> = {};

  currentPage = 1;

  created() {
    const index = "6.4";
    const title = "Historial";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "SMS",
        to: "/sms",
      },
      {
        text: title,
      },
    ]);
    this.getHistory();
    this.getCountries();
  }

  clearDate(data) {
    if (data == null) {
      this.params["start-shipment-at"] = new Date(0);
      this.params["end-shipment-at"] = new Date();
    } else {
      this.params["start-shipment-at"] = data[0];
      this.params["end-shipment-at"] = data[1];
    }
  }

  async getCountries() {
    try {
      CountryService.findCountryCodes().then(data => {
        this.countries = data.map(x => {
          return { id: x.id, name: x.description, code: x.countryCode };
        });
      });
    } catch (e) {
      Notify.gebServerError(e);
    }
  }

  async getHistory(page = this.currentPage) {
    this.fullscreenLoading = true;
    if (this.params.sender == "") this.params.sender = undefined;
    this.currentPage = page;
    this.params.offset = (page - 1) * this.params.limit;
    try {
      this.history = await findShipment(this.params);
      this.fullscreenLoading = false;
    } catch (e) {
      Notify.gebServerError(e);
      this.fullscreenLoading = false;
    }
  }

  getSummaries() {
    const sums = [];
    sums[5] = "Totales";
    sums[6] =
      this.history && this.history["total-receivers"]
        ? this.history["total-receivers"]
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : 0;
    sums[7] =
      this.history && this.history["total-credit-spend"]
        ? this.history["total-credit-spend"]
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : 0;
    sums[8] =
      this.history && this.history["total-mexican-pesos-spend"]
        ? this.history["total-mexican-pesos-spend"]
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : 0;
    sums[8] = `$${sums[8]}`;
    return sums;
  }

  async sortTable(params: SortParams) {
    if (params.order === null) {
      this.params.sort = undefined;
    } else {
      this.params.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    await this.getHistory();
  }
}
