import TharbadClient from "./tharbad_client";
import * as CountryService from "@/api/helpers/Country";
import _ from "underscore";

const enum Service {
  Campaign = "/campaign",
  CampaignDetails = "/campaign/{id}",
  Journey = "/journey",
  JourneySteps = "/journey/{id}/step",
  Template = "/template",
  Shipment = "/shipment",
  ShipmentStop = "/shipment/{id}/stop",
  ShipmentRestart = "/shipment/{id}/restart",
}

interface Pagination {
  offset?: number;
  limit?: number;
  sort?: string;
}

interface FindCampaign extends Pagination {
  id?: number;
  name?: string;
  "country-code"?: string;
  "start-shipment-at"?: string;
  "end-shipment-at"?: string;
}

interface FindTemplate extends Pagination {
  id?: number;
  name?: string;
  content?: string;
  "country-code"?: string;
  sender?: string;
}

interface FindShipment extends Pagination {
  campaign?: string;
  sender?: string;
  "country-code"?: string;
  "shipment-at"?: string;
}

export interface Payload<T> {
  count: number;
  rows: T[];
}

export interface PayloadShipment<T> {
  count: number;
  rows: T[];
  "total-credit-spend": number;
  "total-receivers": number;
  "total-mexican-pesos-spend": number;
}

export interface Campaign {
  id: number;
  name: string;
  "country-code": string;
  "last-shipment-at": string;
  receivers: number;
  sent: number;
  "total-shipment": number;
  "total-credit-spend": number;
  "created-at": Date;
  "updated-at": Date;
}

export interface Template {
  id: number;
  name: string;
  content: string;
  "country-code": string;
  sender: string;
  "created-at": Date;
  "updated-at": Date;
}

export interface Journey {
  id: number;
  "schedule-time": string | null;
  active: boolean;
  "shipment-max-size": number;
  "country-code": string;
  "created-at": Date;
  "updated-at": Date;
}

export interface JourneyStep {
  id: number;
  content: string;
  sender: string;
  order: number;
  "created-at": Date;
  "updated-at": Date;
}

export interface Shipment {
  id: number;
  campaign: JoinedTable;
  "country-code": string;
  sender: string;
  receivers: number;
  "credit-spend": number;
  "mexican-pesos-spend": number;
  "shipment-at": string;
  status: JoinedTable;
  "created-at": Date;
  "updated-at": Date;
}

export interface JoinedTable {
  id: number;
  name: string;
}

export interface JourneyPostItem extends PostItem<Journey> {
  steps: PostItem<JourneyStep>[];
}

export type PostItem<T> = Omit<T, "id" | "created-at" | "updated-at">;

export async function findTemplates(params?: FindTemplate) {
  const client = TharbadClient.getInstance();
  const response = await client.find(Service.Template, params);
  return response.data.data as Payload<Template>;
}

export async function patchTemplate(id: number, params: PostItem<Template>) {
  const client = TharbadClient.getInstance();
  return await client.patch(`${Service.Template}/${id}`, params);
}

export async function postTemplate(params: PostItem<Template>) {
  const client = TharbadClient.getInstance();
  return await client.post(Service.Template, params);
}

export async function deleteTemplate(id: number) {
  const client = TharbadClient.getInstance();
  return await client.delete(`${Service.Template}/${id}`);
}

export async function findJourneys(params: Pagination) {
  const client = TharbadClient.getInstance();
  const response = await client.find(Service.Journey, params);
  const data = response.data.data as Payload<Journey>;
  return data;
}

export async function patchJourney(
  id: number,
  params: Omit<JourneyPostItem, "steps">
) {
  const client = TharbadClient.getInstance();
  return await client.patch(`${Service.Journey}/${id}`, params);
}

export async function postJourney(params: JourneyPostItem) {
  const client = TharbadClient.getInstance();
  return await client.post(Service.Journey, params);
}

export async function deleteJourney(id: number) {
  const client = TharbadClient.getInstance();
  return await client.delete(`${Service.Journey}/${id}`);
}

export async function findJourneySteps(journeyId: number) {
  const client = TharbadClient.getInstance();
  const response = await client.find(
    Service.JourneySteps.replace("{id}", journeyId.toString()),
    {}
  );
  const data = response.data.data;
  return data as JourneyStep[];
}

export async function postJourneySteps(
  journeyId: number,
  params: Omit<JourneyStep, "id" | "created-at" | "updated-at">[]
) {
  const client = TharbadClient.getInstance();
  return await client.post(
    Service.JourneySteps.replace("{id}", journeyId.toString()),
    { steps: params }
  );
}

export async function findShipment(params: FindShipment) {
  const countries = await CountryService.findCountryCodes();
  const client = TharbadClient.getInstance();
  const response = await client.find(Service.Shipment, params);
  const data = response.data.data as PayloadShipment<Shipment>;
  data.rows = data.rows.map(x => {
    x["country-code"] = _.filter(countries, c => {
      return c.countryCode == x["country-code"];
    })[0].description;
    return x;
  });
  return data;
}

export async function findCampaigns(params: FindCampaign) {
  const client = TharbadClient.getInstance();
  const response = await client.find(Service.Campaign, params);
  return response.data.data;
}

export async function findCampaignDetails(id: number) {
  const client = TharbadClient.getInstance();
  const response = await client.find(
    Service.CampaignDetails.replace("{id}", id.toString()),
    {}
  );
  return response.data.data;
}

export async function patchCampaign(id: number, params: PostItem<Campaign>) {
  const client = TharbadClient.getInstance();
  return await client.patch(`${Service.Campaign}/${id}`, params);
}

export async function findCampaignPhones(id: number) {
  const client = TharbadClient.getInstance();
  const response = await client.find(
    `${Service.CampaignDetails.replace("{id}", id.toString())}/phone`,
    {}
  );
  return response.data.data;
}

export async function findCampaignShipments(id: number, params: any) {
  const client = TharbadClient.getInstance();
  const response = await client.find(
    `${Service.CampaignDetails.replace("{id}", id.toString())}/shipment`,
    params
  );
  return response.data.data;
}

export async function stopCampaignShipment(id: number) {
  const client = TharbadClient.getInstance();
  return await client.post(
    `${Service.ShipmentStop.replace("{id}", id.toString())}`,
    {}
  );
}

export async function restartCampaignShipment(id: number) {
  const client = TharbadClient.getInstance();
  return await client.post(
    `${Service.ShipmentRestart.replace("{id}", id.toString())}`,
    {}
  );
}

export async function postCampaignShipment(id: number, params: any) {
  const client = TharbadClient.getInstance();
  return await client.post(
    `${Service.CampaignDetails.replace("{id}", id.toString())}/shipment`,
    params
  );
}

export async function postBulkShipment(params: any) {
  const client = TharbadClient.getInstance();
  return await client.post(`${Service.Campaign}/bulk-shipment`, params);
}
