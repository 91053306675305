
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    method: {
      type: Function,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "eliminar",
    },
  },
  data: function() {
    return { visible: false };
  },
};
